@import "../mixins";
@import "../variables";

.agreement {
  overflow-y: scroll !important;
  -webkit-overflow-scrolling:touch;
}

.faq {
  @include medium1316();
  color: $black50;
  padding-bottom: 3rem;
  .sub-title {
    @include semiBold1524();
    text-align: left;
    color: $black;
    margin-top: 24px;
    margin-bottom: 16px;
  }
  .sub-title:first-child {
    margin-top: 0;
  }
  ol {
    margin-left: 0;
    padding-inline-start: 4.2%;
  }
  a {
    text-decoration: none;
    color: $green;
  }
  a:hover {
    text-decoration: none !important;
    color: $green !important;
  }
}