@import "variables";

@mixin bold1220() {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

@mixin bold1420() {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

@mixin bold1620() {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

@mixin bold1624() {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

@mixin bold1724() {
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

@mixin bold2432() {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}

@mixin medium812() {
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0;
}

@mixin medium1016() {
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0;
}

@mixin medium1216() {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0;
}

@mixin medium1220() {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
}

@mixin medium1224() {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
}

@mixin medium1320() {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
}

@mixin medium1318() {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0;
}

@mixin medium1316() {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0;
}

@mixin medium1420 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
}

@mixin medium1520 {
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
}

@mixin medium1524 {
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
}

@mixin semiBold1014 {
    font-family: Manrope, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0;
    text-align: left;

}

@mixin semiBold1320 {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;
}

@mixin semiBold1316 {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
}

@mixin semiBold1524 {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;
}

@mixin semiBold1724 {
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;
}

@mixin semiBold1420 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;
    text-align: center;
}

@mixin semiBold1520 {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;
    text-align: center;
}

@mixin semiBold1724 {
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;
}

@mixin button {
    display: flex!important;
    flex: 1;
    height: 44px;
    justify-content: center;
    border-radius: 4px;
    align-items: center;
    cursor: pointer;
}

@mixin layout {
    display: flex;
    height: 100%;
    flex: 1;
    flex-direction: column;
}

@mixin borderBottom {
    border-bottom: 0.5px solid $black10;
}

@mixin paddingContent {
    padding: 0 4.2%;
}

@mixin marginContent {
    margin: 0 4.2%;
}

@mixin ellipsis($maxWidth: 100%) {
    white-space: nowrap;
    max-width: $maxWidth;
    text-overflow: ellipsis;
    overflow: hidden;
}

@mixin fixedBottom() {
    width: 100%;
    max-width: 480px;
    position: var(--app-footer);
    bottom: 0;
}

@mixin clipText($width) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    @if $width != null {
        width: $width;
    }
}