@import "../variables";
.datepicker-modal {
  position: fixed!important;
}

.datepicker {
  font-family: Manrope, Arial, sans-serif !important;
}
.datepicker-header {
  padding: 0 0.25rem!important;
}
.datepicker-navbar {
  height: 5rem;
}
.datepicker-navbar-btn {
  text-decoration: none;
  color: $black50!important;
}
.datepicker-navbar-btn:first-child {
  color: $green!important;
}