@import "../variables";
@import "../mixins";

.auth {
  padding: 0 4.2%;
  height: 100vh;
  & > header {
    margin-left: -4.2%;
  }
  &-header {
    height: 20%;
  }
  &-profile-line {
    border-top: 0.5px solid $black10;
    padding: 8px 0;
    &-plus {
      width: 44px;
      height: 44px;
      border-radius: 22px;
      color: $green;
      background-color: $black10;
      text-align: center;
      @include bold2432;
    }
  }
  &-content {
    height: 50%;
    &-or {
      overflow: hidden;
      @include medium1320();
      text-align: center;
      color: $black50;
      margin: 1.5rem 0;
    }
    &-or:before,
    &-or:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      box-sizing: border-box;
      width: 100%;
      height: 0.5px;
      background: $black50;
      border: solid #FFF;
      border-width: 0 10px;
    }
    &-or:before {
      margin-left: -100%;
    }
    &-or:after {
      margin-right: -100%;
    }
  }
  &-line {
    flex: 1;
    flex-direction: row;
    display: flex;
    & > button {
      padding: 0 16px;
      background-position: right center;
      border-color: transparent;
      background-repeat: no-repeat;
      background-origin: content-box;
      @include button();
      justify-content: flex-start;
    }
    & > button:first-child {
      margin-right: 3%;
    }
    &-fb {
      background-color: #3C5A9A;
      background-image: url('#{$iconsSvgUrl}/social/facebook.svg');
      color: $white;
    }
    &-vk {
      background-color: #4986CC;
      background-image: url('#{$iconsSvgUrl}/social/vkontakte.svg');
      color: $white;
    }
    &-yandex {
      background-color: $white;
      background-image: url('#{$iconsSvgUrl}/social/yandex.svg');
      border: 1px solid $black10!important;
      color: $black;
    }
    &-google {
      background-color: $white;
      background-image: url('#{$iconsSvgUrl}/social/google.svg');
      border: 1px solid $black10!important;
      color: $black;
    }
    &-appleId {
      background-color: rgba(23, 28, 28, 1);
      background-image: url('#{$iconsSvgUrl}/social/apple.svg');
      color: $white;
    }
  }
  &-photo {
    & > span {
      padding-left: 16px;
      color: $green;
      @include semiBold1524();
    }
  }
  &-name {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
    color: $black;
    border: 0;
    border-bottom: 1px solid $black20;
    @include medium1524();
    padding-right: 18px;
  }
  &-name::placeholder {
    color: $grey;
  }
  &-footer {
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    & > span {
      @include medium1320();
      text-align: center;
      color: $black50;
    }
  }
  &-welcome {
    margin-top: 60px;
    width: 100%;
  }
}
.hiddenPassword {

}
