@import "../variables";
@import "../mixins";

.profile-settings {
  display: inline-block;
  &-item {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
.checkbox.profile-settings-item {
  padding-left: 8px !important;
}
