@import 'fonts/fonts.css';
@import 'components/layout';
@import 'components/product';
@import 'components/post';
@import 'components/auth';
@import 'components/influencer';
@import 'components/follower';
@import 'components/subscribe';
@import 'components/payment';
@import 'components/categories';
@import 'components/player';
@import 'components/matherial';
@import 'components/formItems';
@import 'components/modal';
@import 'components/profileSettings';
@import 'components/profileAbout';
@import 'components/profileAvatar';
@import 'components/comments';
@import 'components/chats';
@import 'components/notifications';
@import 'components/income';
@import 'components/datepicker';
@import 'components/static';
@import 'components/targets';
@import './variables';
@import './mixins';

* {
  outline: none !important;
}
body {
  padding-right: 0 !important;
  touch-action: pan-x pan-y;
  -webkit-overflow-scrolling:touch;
  &.prevent-scroll{
    overscroll-behavior-y: none;
    //overflow: hidden;
  }
}

html {
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
}

a {
  color: rgba(76, 110, 217, 1) !important;
}
a:hover {
  color: rgba(76, 110, 217, 1) !important;
}
input {
  font-family: Manrope, Arial, sans-serif !important;
  border-radius: 0;
}

:root {
  --app-height: 100vh;
  --app-footer: fixed;
}

html,
body,
main,
#root
{
  font-family: Manrope, Arial, sans-serif !important;
  font-size: 13px;
  min-height: 100vh;
  @media not all and (hover:hover) {
    min-height: var(--app-height);
  }
  color: $black;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  html,
  body,
  main,
  #root
  {
    min-height: -webkit-fill-available;
  }
  .auth {
    display: flex;
    flex-direction: column;
    height: -webkit-fill-available;
    &-header {
      flex: 3 1;
    }
    &-content,
    &-footer {
      flex: 1 1;
      padding-top: 30px;
    }
    .button-regular {
      flex: none;
    }
    & > .h-100 {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

#___gatsby {
  height: 100%;
  background-color: #dbd6d6;
}

#root {
  max-width: 480px;
  margin: auto;
  background-color: #ffffff;
}

.avatar {
  width: 128px;
  height: 128px;
  border-radius: 64px;
}

.bold-12-20 {
  @include bold1220();
}

.bold-14-20 {
  @include bold1420();
}

.bold-16-20 {
  @include bold1620();
}

.bold-16-24 {
  @include bold1624();
}

.bold-17-24 {
  @include bold1724();
}

.bold-24-32 {
  @include bold2432();
}

.medium-8-12 {
  @include medium812();
}

.medium-10-16 {
  @include medium1016();
}

.medium-13-20 {
  @include medium1320();
}
.medium-12-16 {
  @include medium1216();
}
.medium-12-20 {
  @include medium1220();
}
.medium-12-24 {
  @include medium1224();
}
.medium-14-20 {
  @include medium1420();
}

.medium-15-20 {
  @include medium1520();
}

.medium-15-24 {
  @include medium1524();
}

.semiBold-10-14 {
  @include semiBold1014();
}

.semiBold-13-20 {
  @include semiBold1320();
}

.semiBold-13-16 {
  @include semiBold1316();
}

.semiBold-14-20 {
  @include semiBold1420();
}

.semiBold-15-20 {
  @include semiBold1520();
}

.semiBold-15-24 {
  @include semiBold1524();
}

.semiBold-17-24 {
  @include semiBold1724();
}

.tab {
  flex: 1;
  justify-content: center;
  text-align: center;
  height: 44px;
  border-bottom: 0.5px solid #e8e8e8;
  margin-bottom: 16px;
  padding: 0 4px;
  vertical-align: middle;
  line-height: 44px;
  color: $black50;
  cursor: pointer;
}

.tab-active {
  border-bottom-color: #171c1c;
  color: #171c1c;
}

.text-color-sub-title {
  color: $black;
}
.text-color-regular {
  color: $black50;
}
.text-color-black30 {
  color: $black30 !important;
}
.text-color-grey {
  color: $grey;
}
.text-color-green {
  color: $green !important;
}

.text-color-error {
  color: $error;
}

.text-color-white {
  color: $white;
}

.text-subscript {
  font-size: 11px;
}

.text-pre-line {
  white-space: pre-line;
}

.border-bottom-error {
  border-bottom: 1px solid $error !important;
}

header {
  padding: 16px 4.2% 0 4.2%;
  display: flex !important;
  flex-direction: row;
  justify-content: space-around;
  height: 48px;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  position: relative;
  & .selected {
    border-radius: 24px;
    padding: 2px;
    width: 46px;
    height: 46px;
    border: 1px solid $green;
  }
  & progress {
    color: $black5;
    font-size: 13px;
    height: 8px;
    background: $black5;
    vertical-align: middle;
    flex: 1;
    width: 100%;
    border-radius: 4px;
  }
  & progress::-webkit-progress-bar {
    border-radius: 4px;
    background: $black5;
  }
  & progress::-webkit-progress-value {
    border-radius: 4px;
    background: $green;
  }
  & > .right-block {
    position: absolute;
    right: 4.2%;
  }
  & .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
.border-bottom {
  @include borderBottom();
}

.content {
  flex: 1;
  width: 100%;
  flex-direction: column;
  overflow-y: scroll;
  -webkit-overflow-scrolling:touch;
  overflow-x: hidden;
  scrollbar-width: none;
  &-margin {
    margin: 0 4.2%;
  }
  &-padding {
    padding: 0 4.2%;
  }
}

.content::-webkit-scrollbar {
  display: none;
}
.avatarCover {
  display: flex;
  padding: 0 16px;
  height: 160px;
  justify-content: center;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
}
.avatarEmptyCover {
  display: flex;
  height: 168px;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  background-size: cover;

  .edit-icon{
    width: 32px;
    height: 32px;
    position: absolute;

    &.edit-avatar {
      left: 57%;
      bottom: 22px;
    }
    &.edit-background {
      right: 8px;
      top: 8px;
    }
  }
  .follower-score {
    height: 20px;
    right: 8px;
    top: 8px;
    position: absolute;
    background: $white;
    border: 1px solid $light-grey;
    border-radius: 4px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
}
.footer-padding {
  padding-bottom: 56px;
}

.iphone-bottom-padding {
  @media (max-width: 1003px) and (orientation: portrait) {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

.footer {
  padding: 0 20.16px;
  border-top: 1px solid $black5;
  background-color: $white;
  @include fixedBottom();
  z-index: 2;
  @media (max-width: 480px) {
    padding: 0 4.2vw;
  }

  @media (max-width: 1003px) and (orientation: portrait) {
    padding-bottom: env(safe-area-inset-bottom);
  }

  &-icon {
    position: relative;
    &--counter {
      position: absolute;
      left: 0;
      top: 0;
    }
    &--right {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.list-product {
  height: 140px;
  display: flex;
  flex: 1;
}

.button-regular {
  background-color: $green;
  border-color: $green;
  color: $white !important;
  @include button();
}

.button-dark {
  background-color: $black;
  border-color: $black;
  color: $white !important;
  @include button();
}

.button-grey {
  background-color: $black5;
  border-color: $black5;
  color: $black50;
  @include button();
}

.search {
  width: 100%;
  display: flex;
  &-input {
    flex: 1;
    display: flex;
    position: relative;
    &-clear {
      background: $black5;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      cursor: pointer;
      img {
        width: 17px;
      }
    }
    input {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 40px;
      color: $black;
      padding-left: 10px;
      border-radius: 4px 0 0 4px;
      border-width: 0;
      background: $black5;
      @include medium1524();

      &.filter {
        border-radius: 4px;
        background: $black5 url('#{$iconsSvgUrl}/search-grey.svg') no-repeat 8px center;
        padding-left: 32px;
      }

      &::placeholder {
        color: $black50;
      }
    }
  }
  button, button:focus, button:active, button:hover {
    width: 40px;
    height: 40px;
    border-radius: 0 4px 4px 0;
    background: $black10 !important;
    border: 0;
    box-shadow: none !important;
  }
}

.button-regular-disable {
  background-color: $green50;
}
.button-text-regular-disable {
  color: $green50;
}

.button-dark-disable {
  background-color: $black50;
}

.brand {
  color: $white;
  font-size: 17px;
  padding: 0 12px;
  -webkit-text-stroke: 0.04em black;
}

span.deleteicon {
  position: relative;
  & span.iconButton {
    position: absolute;
    display: block;
    top: 5px;
    right: 0;
    width: 16px;
    height: 16px;
    background: url('#{$iconsSvgUrl}/cancel-grey.svg');
    cursor: pointer;
  }
  &-green {
    & span.iconButton {
      background: url('#{$iconsSvgUrl}/cross-green.svg') !important;
      background-size: 16px 16px !important;
    }
  }
  & span.prefix {
    position: absolute;
    display: block;
    top: 8px;
    left: 0;
    @include medium1524();
  }
}

span.countLimitWrap {
  & span.iconButton {
    top: 12px;
  }
}

.green-link {
  color: $green !important;
}

.pointer {
  cursor: pointer !important;
}

.text-area {
  min-height: 150px;
}

.cursor-pointer {
  cursor: pointer;
}
.disabled {
  opacity: 0.6;
}
.badges .disabled {
  opacity: 0.4;
}
.badges img
{
  width: 24px;
}

.height-auto {
  height: auto !important;
}

.hackyOffPageElement {
  position: absolute;
  bottom: -20px;
  display: block;
  height: 10px;
  width: 10px;
}
#scroller {  }

.img-fluid-height {
  max-height: 100%;
  width: auto !important;
}

.unread-messages-counter {
  min-width: 16px;
  height: 16px;
  background: $green;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  color: $white;
  padding: 0 3px;
}
.list-black-circle {
  padding-left: 20px;
}
.list-black-circle>li {
  list-style-type: none;
  color: $green;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  font-size: 14px;
}

.list-black-circle>li:before {
  content: "\2022";
  color: $black;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.delimiter {
  border-left: 1px solid $black10;
}

.font-weight-600 {
  font-weight: 600;
}

textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/*Override reactstrap styles*/
.custom-checkbox {
  padding-left: 25px;
  .custom-control-label {
    &:before, &:after {
      width: 16px;
      height: 16px;
      outline: none;
      top: 2px;
      left: -25px;
    }
  }
  .custom-control-input ~ .custom-control-label {
    &:before {
      border: 2px solid #8A8C8C;
    }
  }
  .custom-control-input:checked ~ .custom-control-label {
    &:before {
      background: $green;
      border: 2px solid $green;
      border-radius: 2px;
    }

    &:after {
      background-image: url("#{$iconsSvgUrl}/checkmark.svg");
    }
  }
}

.modal .modal-dialog {
  max-width: 480px;
}
