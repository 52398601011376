@import "../variables";
@import "../mixins";

.subscribe {
  @include marginContent();
  &-offers {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-content: space-between;
    &-item {
      flex: 1;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      border: 0.5px solid $black10;
      border-radius: 4px;
      margin-left: 8px;
      text-align: center;
      @include semiBold1524();
      cursor: pointer;
      &-discount {
        text-decoration: line-through;
        font-size: 13px;
        line-height: 20px;
      }
    }
    &-item:first-child {
      margin-left: 0;
    }
    &-active {
      background-color: $green;
      color: $white;
    }
    &-disable {
      color: $black30;
    }
  }
}

.button {
  height: 50px;
  @include paddingContent();
}

.cards {
  @include marginContent();
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  position: relative;
  .background-fill {
    background-color: $white;
    position: absolute;
    bottom: 80px;
    z-index: 2;
    width: 100%;
  }
  &-list {
    background: $black5;
    border: 1px solid $black5;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 16px;
    max-height: 270px;
    overflow-y: auto;
    -webkit-overflow-scrolling:touch;
    .card-item {
      margin: 1rem 0;
      display: flex;
      align-items: center;
      .icon {
        margin-right: 1rem;
      }
      > span {
        color: $black;
        font-weight: 600;
        margin-right: 0.5rem;
      }
      .type {
        width: 130px;
      }
    }
    .addCard {
      @include semiBold1316();
      border-top: 2px solid $white;
      color: $black50;
      padding-top: 8px;
      cursor: pointer;
    }
  }
  .card-current {
    @include medium1316();
    > span {
      color: $black50;
    }
    &-body {
      margin: 0.5rem 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      .icon {
        margin-right: 1rem;
      }
      > span {
        color: $black;
        font-weight: 600;
        margin-right: 0.5rem;
      }
      .type {
        margin-right: 1rem;
      }
    }
  }
}