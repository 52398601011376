@import "../mixins";
@import "../variables";

.modal-bottom>.modal-dialog {
  align-items: flex-end;
  width: 441px;
  max-width: 441px!important;
}
@media (max-width: 480px) {
  .modal-bottom>.modal-dialog {
    width: 91.6%;
    margin: auto;
  }
}

.modal-bottom>.modal-dialog>.modal-content {
  background-color: rgba(0,0,0, 0.0);
  border: none;
}

.modal-white-panel {
  display: flex!important;
  flex: 1;
  justify-content: center;
  border-radius: 4px;
  background-color: $white;
  flex-direction: column;
  padding: 10px 0;
}

.modal-bottom .button-regular {
  background-color: $white;
  color: $black!important;
}

.post-actions-confirm .button-regular {
  background-color: $green;
  border-color: $green;
  color: $white !important;
  @include button();
}

.hide-background {
  background: none !important;
  border: none !important;
}