@import "../variables";
@import "../mixins";

@mixin playIcon() {
  margin: auto;
  position: absolute;
  top: 50%;
  bottom: 0;
}

*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

*::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}

//noinspection CssInvalidPseudoSelector
video::-webkit-media-controls {
  display:none !important;
  position: absolute; top: -9999px
}

//noinspection CssInvalidPseudoSelector
video::-webkit-media-controls-enclosure {
  display:none !important;
}

.carousel .galleryVideoWrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  .player {
    &-controls {
      //bottom: 25px!important;
    }
  }
}
.fullscreen {
  .player {
    &-controls {
      bottom: 0!important;
    }
  }
}
.player {
  flex: 1;
  height: 100%;
  background-color: $black;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  &-full {
    width: 100vw;
    min-height: -webkit-fill-available;
    display: flex;
    z-index: 1055;
    position: fixed;
    top:0;
    left: 0;
  }
  &-fullscreen {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    height: 100vh;
  }
  &-video {
    flex: 1;
    display: flex;
    z-index: 1;
    position: relative;
    justify-content: center;
    align-items: center;
    & video {
      width: 100%;
      flex: 1;
      position: relative;
    }
    & > div {
      position: relative;
      border-color: transparent;
      display: flex!important;
      width: 0;
      height: 40px;
      justify-content: center;
      border-radius: 20px;
      align-items: center;
      cursor: pointer;
      align-self: center;
      left: -50%;
    }
    .play-icon {
      @include playIcon();
    }
  }

  &-controls {
    z-index: 2;
    background-color: $black10;
    display: flex;
    padding: 0 16px 16px 16px;
    position: absolute;
    width: 100%;
    flex-direction: column;
    &:-moz-full-screen { position: fixed; }
    &:-webkit-full-screen { position: fixed; }
    &:-ms-fullscreen { position: fixed; }
    &:fullscreen { position: fixed; }
    & > div > progress {
      font-size: 13px;
      height: 6px;
      border: none;
      background: $white10;
      vertical-align: middle;
      border-radius: 2px;
      width: 100%;
    }
    & > div > progress[value]::-webkit-progress-value::after {
      content: '';
      width: 6px;
      height: 6px;
      position: absolute;
      border-radius: 100%;
      right: 7px;
      top: 0;
      background-color: black;
    }

    & > div > progress::-moz-progress-bar {
      background: #ffffff;
    }
    & > div > progress::-webkit-progress-bar {
      background: rgba(255, 255, 255, 0.5);
      border-radius: 2px;
    }
    & > div > progress::-webkit-progress-value {
      background: #ffffff;
      border-radius: 2px;
    }

    &  > input[type=range] {
      -webkit-appearance: none;
      flex: 1;
    }
    &  > input[type=range]:focus {
      outline: none;
    }
    &  > input[type=range]::-webkit-slider-runnable-track {
      flex: 1;
      height: 2px;
      cursor: pointer;
      background: $black10;
    }
    &  > input[type=range]::-webkit-slider-thumb {
      border: 0.5px solid $black10;
      height: 8px;
      width: 8px;
      border-radius: 4px;
      background: $white;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -3px;
    }
    &  > input[type=range]:focus::-webkit-slider-runnable-track {
      background: $black10;
    }
    &  > input[type=range]::-moz-range-track {
      width: 100%;
      height: 4.4px;
      cursor: pointer;
      background: $black10;
      border-radius: 1px;
      border: 1.1px solid $black50;
    }
    &  > input[type=range]::-moz-range-thumb {
      border: 2.5px solid $black50;
      height: 18px;
      width: 9px;
      border-radius: 3px;
      background: $black50;
      cursor: pointer;
    }
    &  > input[type=range]::-ms-track {
      width: 100%;
      height: 4.4px;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }
    &  > input[type=range]::-ms-fill-lower {
      background: $black50;
      border: 1.1px solid $black10;
      border-radius: 2px;
    }
    &  > input[type=range]::-ms-fill-upper {
      background: $black50;
      border: 1.1px solid $black10;
      border-radius: 2px;
    }
    &  > input[type=range]::-ms-thumb {
      border: 2.5px solid $black10;
      height: 18px;
      width: 9px;
      border-radius: 3px;
      background: $black50;
      cursor: pointer;
    }
    &  > input[type=range]:focus::-ms-fill-lower {
      background: $black50;
    }
    &  > input[type=range]:focus::-ms-fill-upper {
      background: $black50;
    }
    & .dropdown-item.active {
      background-color: $green!important;
    }
    &-button {
      color: $white!important;
      display: flex!important;
      width: 20px;
      height: 20px;
      justify-content: center;
      border-radius: 2px;
      align-items: center;
      cursor: pointer;
    }
    &-back {
      width: auto;
      display: flex;
      @include medium1220();
      height: 30px;
      align-items: center;
      padding-left: 12px;
      padding-top: 8px;
      & > span {
        width: 100%;
        text-align: left;
      }
    }
    &-dropdown {
      width: auto;
      display: flex;
      flex-direction: column;
      @include medium1220();
      & > span {
        width: 100%;
        text-align: right;
      }
    }
    &-dropdown-part {
      display: flex;
      @include medium1220();
      height: 36px;
      align-items: center;
      border-bottom: 0.5px solid #2E3333;
      padding: 0 12px;
    }
    .player-video-properties {
      position: absolute;
      will-change: transform;
      top: 0;
      left: 0;
      transform: translate3d(0px, -40px, 0px);
      min-width: 125px!important;
      padding: 8px!important;
      background-color: $black;
      color: $white;
      border: 0.5px solid #2E3333;
      box-sizing: border-box;
      border-radius: 4px;
    }

    .player-video-speed {
      position: absolute;
      will-change: transform;
      top: 0;
      left: 0;
      transform: translate3d(0px, -172px, 0px);
      min-width: 125px!important;
      padding: 8px!important;
      background-color: $black;
      color: $white;
      border: 0.5px solid #2E3333;
      box-sizing: border-box;
      border-radius: 4px;
    }

    .player-video-quality {
      left: -9px!important;
      top: 27px!important;
      min-width: 73px!important;
      padding: 8px!important;
    }
  }
}

.player-audio {
  height: 60px;
  background-color: $green10;
  border-radius: 8px;
  padding: 0 8px 0 8px;
  display: flex;
  &>audio {
    width: 0;
    height: 0;
    display: none;
  }
  &-controls {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    &-center {
      flex-direction: column;
      display: flex;
      flex: 1;
      margin-left: 12px;
      justify-content: space-between;
      &>div>span.deleteicon>span {
        display: none;
      }
      &>input[type="range"] {
        --thumbSize: 8px;
        --trackSize: 4px;
        --thumbBg: $green;
        --trackBg: rgba(23, 148, 148, 0.3);
        --progressBg: $green;
        --webkitProgressPercent: 0%;
      }
      &>input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 0;
        height: 0;
        background-color: $green;
        border-radius: calc(var(--thumbSize) / 2);
        border: calc(var(--thumbSize) / 2) solid $green;
        background: var(--thumbBg);
        cursor: pointer;
        margin-top: -2px;
      }
      &>input[type='range'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        height: var(--trackSize);
        width: 100%;
        margin: 0;
        padding: 0;
        border-radius: 2px;
      }
      &>input[type='range']::-webkit-slider-runnable-track {
        height: var(--trackSize);
        border-radius: calc(var(--thumbSize) / 2);
        background-image: linear-gradient(
                        90deg,
                        $green var(--webkitProgressPercent),
                        rgba(23, 148, 148, 0.3) var(--webkitProgressPercent)
        );
      }
      .filename {
        @include ellipsis(calc(100vw - 100px));
        margin-bottom: 4px;
      }
    }
  }
  &-input {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 16px;
    color: #171C1C;
    border: 0;
    @include medium1016();
    padding-right: 0;
    border-radius: 0;
    -webkit-appearance: none;
  }
}
.player-fullscreen .player-video-properties {
  left: -28px!important;
}

.screenshot {
  flex: 1;
  display: flex;
  &-no-image {
    width: 100%;
    flex: 1;
    background: $black url("https://insider-static.cdnvideo.ru/insider/images/iconssvg/play.svg") no-repeat center center;
  }
  &-main {
    height: 432px;
    @media (max-width: 480px) {
      height: 92vw;
    }
  }
  &-image {
    width: 100%;
    @media (max-width: 480px) {
      object-fit: contain;
    }
  }
  & > div {
    position: relative;
    border-color: transparent;
    display: flex!important;
    width: 0;
    height: 40px;
    transform: translateY(-50%);
    justify-content: center;
    border-radius: 20px;
    align-items: center;
    cursor: pointer;
    align-self: center;
    left: -50%;
  }
  .play-icon {
    @include playIcon();
    margin-top: 0;
  }
}

.carousel__slider {
  height: 100vh;
}

.fullscreenSlide > .carousel__slider {
  z-index: 2000;
}

@keyframes xAxis {
  50% {
    animation-timing-function: ease-in;
    transform: translateX(10px);
  }
}
@keyframes yAxis {
  50% {
    animation-timing-function: ease-out;
    transform: translateY(10px);
  }
}
