@import "../variables";
@import "../mixins";

.list-product {
  height: 140px;
  display: flex;
  flex: 1;
  align-items: center;
  margin: 0 16px;
  border-bottom: 0.5px solid #e8e8e8;
  &-image {
    width: 92px;
    height: 92px;
  }
  &-rating {
    position: relative;
    bottom: 24px;
    vertical-align: bottom;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  &-rating >span {
    font-size: 13px;
    line-height: 13px;
    color: $white;
    font-weight: 500;
    padding-left: 4px;
  }
  &-rating >img {
    width: 16px;
    height: 16px;
  }
  &-content {
    flex: 1;
    padding-left: 16px;
    flex-direction: column;
    min-width: 0;

    &-title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0;
      margin-bottom: 8px;
    }
    &-desc {
      @include medium1320();
      margin-bottom: 8px;
      color: rgba(23, 28, 28, 0.5);
      max-height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-price {
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
  }
}