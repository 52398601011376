@import "../variables";
@import "../mixins";

.categories {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  & > div {
    border: 1px solid $black10;
    border-radius: 4px;
    color: $black;
    @include semiBold1520();
    padding: 16px;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    user-select: none;
  }
  &-item-active {
    background-color: $green;
    color: $white!important;
    border-color: $green!important;
  }
}