@import "../mixins";
@import "../variables";

.comments {
  flex: 1 1 auto;
  height: 100vh;
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
  header {
    background: $white;
  }
  &-content {
    @include paddingContent();
    flex: 1 1 auto;
    overflow-y: auto;
    -webkit-overflow-scrolling:touch;
    min-height: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &-item {
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 24px;

    &.pinned {
      padding: 16px 4.2%;
      margin: 0 -4.2%;
      border-top: 1px solid $black5;
      border-bottom: 1px solid $black5;
      background: $white;
    }
    .main {
      flex: 1;
      font-size: 14px;
      line-height: 20px;
      .text {
        flex: 1;

        .paragraph {
          margin-left: 0.5rem;
          display: inline;
          word-break: break-word;
          font-weight: 500;
        }
      }
      .toolbar {
        color: $grey;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
    .self-like {
      img {
        margin-left: 1rem;
        width: 18px;
        height: 18px;
      }
    }
    .comment-like {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      img {
        margin-left: 1rem;
      }
    }
  }
  .replyMargin {
    margin-left: 45px !important;
  }

  &-hr {
    margin: 0.5rem 0;
    height: 10px;
    span {
      left: 35px;
      font-size: 14px;
      font-weight: 600;
    }
  }

  &-showMore {
    border-top: 1px solid $light-grey;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 15px;
    margin-top: 0.5rem;
  }

  &-filters {
    background: $light-grey;
    padding: 0 8px;
    border-radius: 6px;
    color: #979D9D;
    height: 24px;
    display: flex;
    align-items: center;
    @include semiBold1420();

    &-modal {
      position: relative;
      &--close {
        position: absolute;
        right: 15px;
      }
      .custom-checkbox {
        font-weight: 600;
        font-size: 14px;
        height: 44px;
      }
    }
  }
}

.comment-editor {
  display: flex;
  align-items: center;
  @include medium1320();
  textarea {
    width: 100%;
    border-radius: 50px;
    border: 1px solid $light-grey;
    padding: 8px 16px;
    resize: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    @include medium1520();
  }

  &--button {
    width: 24px;
    height: 24px;
    margin-left: 0.5rem;
  }
}

.bottom-tag {
  //border: 1px solid red; //чтобы лучше видеть тэг который нужен для прокрутки
  height: 20px;
}
