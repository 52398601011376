.template {
  .header {
    padding: 40px 0;
  }
  .content,
  .footer {
    padding: 0 70px;
  }
  .footer {
    border: 0;
    background-color: transparent;
  }
  .button-center {
    padding: 0 20%;
  }
  a.text-color-green {
    color: #179494!important;
  }


}