
//colors
$black: #171C1C;
$black50: rgba(23, 28, 28, 0.5);
$black30: rgba(23, 28, 28, 0.3);
$black20: rgba(23, 28, 28, 0.2);
$black10: rgba(23, 28, 28, 0.1);
$black5: rgba(23, 28, 28, 0.05);
$error: #FF8383;
$green: #179494;
$green10: #E5F2F2;
$green50: rgba(23, 148, 148, 0.5);
$greenDark: #174040;
$white: #FCFCFC;
$white10: rgba(252, 252, 252, 0.1);
$gray: #2E3232;
$grey: #8C9393;
$light-grey: #EEEEEE;

//urls
$imagesUrl: "https://insider-static.cdnvideo.ru/insider/images";
$iconsSvgUrl: "https://insider-static.cdnvideo.ru/insider/images/iconsSvg";