@import "../variables";
@import "../mixins";

.avatar-delete {
  margin-bottom: 1rem;
}

.avatar-list-block {
  @include marginContent();
  overflow-x: scroll;
  text-align: center;
  scrollbar-width: none;
  .avatar-list {
    display: inline-flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 15px;
    div {
      width: 44px;
    }
    div:first-child {
      margin-left: 1rem !important;
    }
    div:last-child {
      margin-right: 1rem !important;
    }
  }
}

.avatar-list-block::-webkit-scrollbar {
  height: 0;
}

.user-avatar {
  border: 4px solid $white;

  &-vip {
    border: 2px solid $green;
  }
}

.avatar-container {
  .cropper-view-box {
    border-radius: 50%;
  }
}
