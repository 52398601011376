@import "../variables";
@import "../mixins";

.modal-content>.carousel {
  height: 100vh;
}
@mixin additional {
  background-color: $green;
  position: relative;
  line-height: 0;
  img {
    opacity: 0.5;
  }
  div {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include semiBold1724();
    color: $white;
  }
}

.list-post {
  flex:1;
  &:not(:last-child) {
    border-bottom: 16px solid rgba(23, 28, 28, 0.05);
  }
  &-header {
    display: flex;
    height: 44px;
    flex-direction: row;
    flex: 1;
    margin: 0 4.2%;
    &-image {
      width: 44px;
      height: 44px;
      border-radius: 22px;
    }
    &-content {
      padding-left: 8px;
      display: flex;
      flex: 1;
      flex-direction: column;
    }
    &-top {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
    }
    img.options {
      width: 24px;
      height: 24px;
    }
  }
  &-teaser {
    margin: 16px 4.2%;
    color: $black50;
  }
  &-teaser-title {
    margin: 16px 4.2%;
    color: $black;
  }

  &-content-vertical {
    display: flex;
    flex-direction: row;
    &-carousel {
      margin-left: 8px;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      display: flex;
      overflow: hidden;
      .additional {
        @include additional();
      }
    }
  }

  &-content-horizontal {
    display: flex;
    flex-direction: column;
    &-main {
      flex: 1;
    }
    &-secondary {
      max-height: 120px;
    }
    &-carousel {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      max-width: 480px;
      overflow-x: hidden;
      overflow-y: hidden;
      & > img:not(:first-child), & > div:not(:first-child) {
        margin-left: 8px;
      }
      .additional {
        @include additional();
      }
      & .screenshot-image {
        height: 100%;
        width: auto;
      }
    }
    @media (max-width: 480px) {
      &-carousel {
        max-width: 91.6vw;
      }
    }
  }

  &-blurred {
    width: 100%;
    background: no-repeat 0 0;
    background-size: 100%;
    height: 445px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 480px) {
    &-blurred {
      height: calc(0.928 * 100vw);
    }
  }
  &-lock {
    margin-top: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  &-lock >img {
    margin-top: 44px;
    margin-bottom: 8px;
  }
  &-lock >span {
    color: $white;
  }
  &-button {
    height: 44px;
    width: 100%;
    padding: 0 4.2%;
    align-self: flex-end;
    margin-bottom: 16px;
    margin-top: auto;
  }
  &-icons {
    margin: 1rem 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    img {
      margin-left: 1rem;
    }
    img:first-child {
      margin-left: 0;
    }
    span {
      @include medium1316();
      color: $white;
      margin-left: 0.3rem;
    }
  }
  &-gallery {
    max-width: 100%!important;
    @media (orientation: landscape) and (max-width: 1280px) {
      max-width: calc(100% - 20px)!important;
      min-height: calc(100vh - 140px);
      max-height: 100vh;
    }

    margin: 0 auto!important;
    &-background {
      background-color: $black;
      &>.modal {
        background-color: $black;
      }
    }
    &-image {
      background-color: $white;
      max-width: 480px;
      max-height: 90vh;
      @media (orientation: landscape) and (max-width: 1280px) {
        max-width: none;
        max-height: calc(100vh - 40px);
      }
    } @media (max-width: 480px) {
      &-image {
        max-width: 100vw;
      }
    }
    &-close {
      right: 24px;
      top: 24px;
      position: absolute;
      cursor: pointer;
      @media (orientation: landscape) {
        position: fixed;
        top: 12px;
      }
      z-index: 1500;
    }
    &>.modal-content {
      background-color: rgba(0,0,0, 0.0);
      border: none;
      position: absolute;
      top: 0;
    }
    & .carousel__back-button {
      position: absolute;
      top:50%;
      transform: translateY(-50%);
      background: none;
      border: none;
    }
    & .carousel__next-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      background: none;
      border: none;
    }
    & .carousel__inner-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      @supports not (-webkit-touch-callout: none) {
        height: 100vh;
      }
      @supports (-webkit-touch-callout: none) {
        min-height: -webkit-fill-available;
      }
      & > img {
        background-color: $white;
        max-width: 480px;
        max-height: 85%;
      }
      @media (max-width: 480px) {
        & > img {
          max-width: 100vw;
        }
      }
    }
    &-counter {
      -webkit-text-stroke: 0.5px $black;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.w-100-image {
  max-height: 480px;
  object-fit: contain;
}

.addPostTextArea {
  border-radius: 0;
  -webkit-appearance: none;
  @include semiBold1524();
  text-align: left;
}

.addPostTextArea:focus {
  border-color: $black;
}

.addPostTextArea::placeholder {
  @include medium1524();
  text-align: left;
}

.modalBottomButtonBlock {
  display: flex;
  justify-content: space-between;
  border-top: 0.5px solid $black20;
  &>div:not(:first-child) {
    border-left: 0.5px solid $black20;
  }
}

.infinite-scroll-component {
}

.infinite-scroll-component::-webkit-scrollbar {
  display: none;
}
