@import "../mixins";
@import "../variables";

@mixin targetListItem() {
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 0.5px solid $black10;
  .amount {
    @include semiBold1524();
    text-align: left;
    color: $black50;
    b {
      color: $black;
    }
  }
  & progress {
    color: $black5;
    font-size: 13px;
    height: 8px;
    background: $black5;
    vertical-align: middle;
    flex: 1;
    width: 100%;
    border-radius: 4px;
  }
  & progress::-webkit-progress-bar {
    border-radius: 4px;
    background: $black5;
  }
  & progress::-webkit-progress-value {
    border-radius: 4px;
    background: $green;
  }
  .title {
    @include medium1520();
    color: $black50;
    margin-top: 0.5rem;
  }
  .black {
    color: $black;
  }
  .edit-button {
    @include semiBold1316();
    color: $green;
    margin-top: 0.5rem;
  }
  .bottom-block {
    display: flex;
    justify-content: space-between;
  }
}

.targets {
  .description {
    @include medium1320();
    color: $black50;
  }
  &-list {
    &-item {
      @include targetListItem();
    }
    .closed {
      b {
        color: $black50;
      }
    }
  }
  .subTitle {
    @include semiBold1520();
    text-align: left;
  }
}

.target-edit {
  .description {
    @include medium1320();
    color: $black50;
  }
}

.target-select {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  position: relative;
  .background-fill {
    background-color: $white;
    position: absolute;
    top: 55px;
    z-index: 2;
    width: 100%;
  }
  &-list {
    background: $black5;
    border: 1px solid $black5;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 16px;
    max-height: 330px;
    overflow-y: auto;
    -webkit-overflow-scrolling:touch;
    &-item {
      @include targetListItem();
    }
    .defaultTarget {
      @include semiBold1524;
      text-align: left;
      border-bottom: 2px solid $white;
      color: $black;
      padding-top: 8px;
      padding-bottom: 8px;
      cursor: pointer;
    }
  }
  &-current {
    @include medium1316();
    > span {
      @include bold1724();
      color: $black;

    }
    &-body {
      margin: 0.5rem 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      .icon {
        margin-right: 1rem;
      }
      > span {
        color: $black;
        font-weight: 600;
        margin-right: 0.5rem;
      }
      .type {
        margin-right: 1rem;
      }
    }

  }
}