@import "../mixins";
@import "../variables";

.btnChange {
  @include semiBold1316();
  color: $green !important;
}

.follower-profile {
  &-title {
    @include bold2432();
    font-family: Manrope, sans-serif;
    text-align: left;
    display: block !important;
  }
  &-item {
    margin-top: 24px;
    margin-bottom: 24px;
    padding-left: 0 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .icon {
      width: 20px;
      display: inline-flex;
      justify-content: center;
      margin-right: 1rem;
    }
  }
  &-account {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 24px 0;
    border-top: 1px solid $black10;
    border-bottom: 1px solid $black10;
    > img {
      width: 100px;
      height: 100px;
      margin-right: 16px;
    }
    .info {
      flex: 1;
      @include medium1316();
      color: $black50;
      .title {
        @include bold1724();
        color: $black;
        margin-bottom: 8px;
        span {
          @include semiBold1320();
          color: $green;
        }
      }
      .mail {
        height: 16px;
      }
    }
  }
  .logout {
    @include semiBold1524();
    color: $error;
  }
}

.follower-subscriptions {
  &-item {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid $black10;
    .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      > img {
        margin-right: 8px;
        width: 44px;
        height: 44px;
      }
      .info {
        flex: 1;
        .title {
          @include bold1724();
        }
        .status {
          @include medium1316();
          color: $black50;
        }
      }
    }
    .extra-info {
      margin-top: 16px;
      .description {
        @include medium1316();
        margin-bottom: 8px;
        color: $black50;
        b {
          color: $black;
        }
      }
      .cost {
        @include semiBold1316();
      }
    }
    .payment {
      @include medium1316();
      line-height: 21px;
      color: $black50;
      .title {
        margin-top: 24px;
        margin-bottom: 16px;
        @include bold1724();
        color: $black;
      }
      span {
        color: $black;
      }
    }
    .error {
      @include medium1316();
      color: $error;
    }
  }
}

.follower-payment-info {
  padding-top: 16px !important;
  &-item {
    @include semiBold1524();
    margin-bottom: 24px;
  }
}

.follower-payment-history {
  padding-top: 16px !important;
  .date-block {
    @include bold1724();
  }
  &-item {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $black10;
    .amount {
      @include semiBold1520();
      text-align: left;
      margin-bottom: 0.5rem;
    }
    .title {
      @include semiBold1316();
      color: $green;
      margin-bottom: 0.5rem;
    }
    .from {
      @include medium1316();
      color: $black50;
      margin-bottom: 1rem;
      b {
        @include semiBold1316();
        color: $black;
      }
    }
    .cardInfo {
      @include medium1316();
      color: $black50;
      margin-bottom: 0.5rem;
      b {
        color: $black;
      }
    }
  }
  &-item:last-child {
    border: 0;
  }
}

.follower-payment-cards {
  .title {
    @include bold1724();
  }
  &-item {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $black10;
    .cardInfo, .cardExp {
      @include medium1316();
      font-weight: 500;
      color: $black50;
      margin-bottom: 8px;
      b {
        font-weight: 500;
        color: $black;
      }
    }
    .error {
      @include medium1316();
      margin-bottom: 0.5rem;
      color: $error;
    }
    .delete {
      @include semiBold1316();
    }
  }
  &-item:last-child {
    border: 0;
  }
  &-message {
    text-align: left;
    font-weight: 600;
    color: $green;
  }
}

.influencer-follower-profile {
  .nickName {
    @include bold1724();
    text-align: center;
    margin-bottom: 8px;
  }
  .subscribeDate {
    @include medium1316();
    color: $black50;
    text-align: center;
    margin-bottom: 1rem;
  }
  .subTitle {
    @include bold1724();
    margin-bottom: 16px;
  }
  .income-list {
    &-item {
      padding-bottom: 16px;
      border-bottom: 1px solid $black10;
      margin-bottom: 16px;
      .amount {
        @include semiBold1520();
        color: $green;
        text-align: left;
      }
      .date {
        @include medium1320();
        color: $black50;
      }
      .type {
        @include medium1316();
        color: $black50;
        margin-bottom: 8px;
      }
      .title {
        @include medium1316();
        color: $black;
      }
    }
    &-item:last-child {
      border: 0;
    }
  }
}
.followers-count {
  @include semiBold1524();
  color: $black50;
}
.follower-list-wrapper {
  header, .subheader {
    background: $white;
  }
  .subheader {
    border-bottom: 1px solid $light-grey;
  }
  .random-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include medium1524();
    color: $black50;
    height: 48px;
    .button-wrapper .button-regular {
      width: 32px;
      height: 32px;
    }
    .input-wrapper {
      width: 32px;

      margin: 0 8px;
      .random-input {
        height: 32px;
        text-align: center;
        padding-right: 5px;
        background: $white;

        &.error {
          border-color: $error;
        }
      }
    }
  }
}
.influencer-follower-list {
  margin-bottom: 56px;
  .follower-list {
    padding-top: 24px;
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .item-content {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}