@import "../mixins";
@import "../variables";

.form-item {
  border: 0;
  @include borderBottom();
  width: 100%;
}

.password {
  position: relative;
  .hide-show-button {
    position: absolute;
    display: block;
    top: 15px;
    right: 0;
    width: 18px;
    height: 18px;
    cursor: pointer;
    img {
      width: 18px;
      height: 18px;
    }
  }
}

.inputMark {
  height: 44px;
  width: 100%;
  background: $green10;
  border-radius: 4px;
  padding: 1rem;
  display: inline-flex;
  align-items: center;
}

.countLimitWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .countLimitVal {
    color: $black50;
  }
}

.paragraph {
  white-space: pre-line;
  span {
    color: $green;
  }
}

.checkbox {
  display: flex;
  flex-direction: row;
  .label {
    @include semiBold1524();
    flex-grow: 1;
    text-align: left;
  }
}

.radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  .label {
    @include semiBold1524();
    flex-grow: 1;
    text-align: left;
    margin-left: 0.5rem;
  }
}
input[type='radio'] {
  width: 0;
}
input[type='radio']:after {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  top: -6px;
  left: -24px;
  position: relative;
  background-color: $white;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid $green;
}

input[type='radio']:checked:after {
  background-clip: content-box;
  -webkit-background-clip: content;
  -moz-background-clip: padding;
  padding: 2px;
  background-color: $green;
  border: 1px solid $green;
}

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $black20;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 2px;
  bottom: 1px;
  background-color: $white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $green;
}

input:focus + .slider {
  box-shadow: 0 0 1px $green;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}
.slider.round:before {
  border-radius: 50%;
}

.hr-title {
  @include medium1316();
  height: 30px;
  min-height: 10px;
  color: $grey;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-line {
    position: absolute;
    top: 8px;
    border-bottom: 1px solid $light-grey;
    width: 100%;
  }
  span {
    position: absolute;
    background-color: $white;
    padding: 0 1rem;
  }
}

.filters {
  display: flex;
  justify-content: flex-start;
  &-item {
    border: 1px solid $black10;
    border-radius: 4px;
    padding: 4px 8px;
    @include semiBold1520();
    margin-right: 8px;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .active {
    border: 1px solid $green;
    background-color: $green;
    color: $white;
  }
}

.filterValuesModal {
  display: flex;
  align-items: flex-end;
  min-height: calc(100% - 1rem);
  .modal-content {
    border: none !important;
  }
  &Content {
    padding: 16px;
    max-height: 50vh;
    overflow: hidden;
    background-color: #f7f7f7;
    .title {
      display: flex;
      justify-content: space-between;
      @include semiBold1724();
    }
    .values-list {
      overflow-y: scroll;
      -webkit-overflow-scrolling:touch;
      &-item {
        @include semiBold1524();
        color: $green;
        text-align: left;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $black10;
      }
      &-item:last-child {
        border: 0;
      }
    }
    .body {
      div {
        position: relative;
      }
    }
  }
}

.checkboxAgreement {
  display: flex;
  flex-direction: row;
  .label {
    @include medium1316();
    color: $black50;
    flex-grow: 1;
    text-align: left;
    span {
      color: $green;
      cursor: pointer;
    }
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 32px;
  }
  .square {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    border: 1px solid $black10;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fill {
    display: none;
    width: 8px;
    height: 8px;
    background-color: $green;
    border-radius: 2px;
  }
  input:checked + .square .fill {
    display: block;
  }
}
