@import '../variables';
@import '../mixins';

.layout {
  @include layout();
}
.layout3 {
  @include layout();
  background-color: $gray;
  position: relative;
  min-height: 100vh;

  &.select-avatar {
    .layout3-footer {
       height: 110px;
    }
  }

  &-cancel {
    position: absolute;
    top: 62px;
    left: 18px;
    cursor: pointer;
  }

  &-content {
    background-color: $black;
    position: relative;
    overflow-y: auto;
    -webkit-overflow-scrolling:touch;

    > div {
      max-height: 100%;
    }
    > div, img {
      max-width: 100%;
    }
  }

  header.mb-4 {
    height: 60px;
    padding: 0 4.2%;
    margin-bottom: 0 !important;
  }
  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4.2%;
    height: 70px;
    background: $gray;
  }

  .upload-button {
    background-color: #179494;
    color: $white;
    display: flex !important;
    height: 44px;
    justify-content: center;
    border-radius: 4px;
    align-items: center;
    cursor: pointer;
    @include semiBold1524();
  }
}

.divider {
  height: 16px;
  background-color: $black5;
}

.page-loader {
  display: flex;
  flex: 1;
  justify-content: center;
  --full-height: 100vh;
  &:not(.hasHeader):not(.hasHeader) {
    height: var(--full-height);
  }
  &.hasHeader {
    height: calc(var(--full-height) - 64px);
  }
  &.hasHeader {
    height: calc(var(--full-height) - 56px);
  }
  &.hasHeader.hasFooter {
    height: calc(var(--full-height) - 120px);
  }
}

.page-loader-header {
  height: calc(100vh);
}

.logo-spinner {
  .spinner {
    position: relative;
    &:not(.bg-none):before {
      position: absolute;
      content: "";
      width: 25%;
      left: 50%;
      margin-left: -12.5%;
      height: 50%;
      top: 50%;
      margin-top: -25%;
      background: url('#{$iconsSvgUrl}/logo/keyhole.svg') 0 0 no-repeat;
      background-size: cover;
    }
    &.welcome:before {
      width: 10px;
      margin-left: -5px;
      height: 20px;
      margin-top: -10px;
    }
    &.loading {
      img, svg {
        animation: spin 3s linear infinite;
      }
    }
  }
}

@keyframes spin { 100% { transform:rotate(360deg); } }

.menu-list {
  padding-top: 16px !important;
  &-item {
    @include semiBold1524();
    margin-bottom: 24px;
  }
}
