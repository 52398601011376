@import "../mixins";
@import "../variables";

.income {
  @include paddingContent();
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  .balance {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid $black10;
    .top {
      display: flex;
      justify-content: space-between;
      @include semiBold1524();
      color: $black50;
      margin-bottom: 8px;
      .withdraw {
        color: $green;
        img {
          margin-right: 8px;
        }
      }
    }
    span {
      @include bold2432();
      color: $green;
    }
  }
  .title {
    @include bold1724();
    margin-bottom: 16px;
  }
  .totals {
    @include semiBold1316();
    color: $black50;
    margin-bottom: 25px;
    span {
      color: $green;
    }
  }
  .scroll-block {
    border-top: 1px solid $black10;
    flex-grow: 2;
    overflow-y: auto;
    -webkit-overflow-scrolling:touch;
    overflow-x: hidden;
    scrollbar-width: none;
  }
  &-list {
    .date-block {
      @include bold1724();
      margin-top: 16px;
    }
    &-item {
      padding-bottom: 16px;
      border-bottom: 1px solid $black10;
      .amount {
        @include semiBold1520();
        color: $green;
        text-align: left;
        margin-bottom: 8px;
      }
      .type {
        @include medium1316();
        color: $black50;
        margin-bottom: 8px;
      }
      .title {
        @include semiBold1316();
      }
      .buyer {
        margin-top: 16px;
        @include semiBold1316();
        color: $black50;
        b {
          color: $black;
        }
      }
    }
  }
}