@import "../mixins";
@import "../variables";

.notifications {
  &-title {
    @include bold2432();
    font-family: Manrope;
    text-align: left;
    display: block !important;
  }
  .subTitle {
    display: inline-flex;
    align-items: flex-end;
    @include medium1316();
    color: $black50;
    margin-bottom: 16px;
    img {
      margin-left: 8px;
    }
  }
  @include paddingContent();
  border-top: 1px solid $black5;
  padding-top: 16px;
  &-item {
    display: flex;
    margin-bottom: 16px;
    .avatar {
      width: 44px;
      height: 44px;
      margin-right: 8px;
    }
    .block {
      flex: 1;
      .author {
        @include semiBold1724();
        text-align: left;
        margin-bottom: 4px;
        span {
          @include medium1320();
          color: $black50;
          margin-left: 4px;
        }
      }
      .body {
        @include medium1316();
        color: $black50;
        span {
          color: $green;
        }
        .message {
          @include medium1316();
          color: $black;
          margin-top: 4px;
        }
      }
    }
  }
  .pinned {
    margin-bottom: 40px;
  }
  &-group-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include medium1520();
    color: $black;
    margin-bottom: 16px;
    span {
      color: $green;
    }
    img {
      margin-right: 12px;
    }
  }
}