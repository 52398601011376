@import '../variables';
@import '../mixins';
:root {
  --app-height: -webkit-fill-available;
}
.chat {
  background: $white;
  .tab {
    &-title {
      position: relative;
      &--counter {
        position: absolute;
        top: 5px;
        right:10px;
      }
    }
  }
  &-filters {
    background: $light-grey;
    padding: 0 8px;
    border-radius: 6px;
    color: #979D9D;
    height: 24px;
    display: flex;
    align-items: center;
    @include semiBold1420();

    &-modal {
      position: relative;
      &--close {
        position: absolute;
        right: 15px;
      }
      .custom-checkbox {
        font-weight: 600;
        font-size: 14px;
        height: 44px;
      }
    }
  }
  &-title {
    font-size: 24px;
    font-weight: bold;

    &-wrapper {
      margin-top: 15px;
      border-bottom: 0.5px solid $light-grey;
      padding-bottom: 15px;
    }
  }
  &-content {
    flex: 1;
  }
  &-search {
    margin: 15px 0 7px;
  }
  &-item {
    border-bottom: 0.5px solid $black10;
    padding: 10px 0 12px;
    height: 76px;
    cursor: pointer;
    &__avatar {
      height: 44px !important;
    }
    &__message {
      min-width: 0;
      &--text {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: $black50;

        @include ellipsis();

        &-payment {
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          color: $green;
          margin-right: 7px;
        }
      }
      &--hidden {
        color: $white;
      }
    }

    &__time {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: $black50;
    }
    &__name {
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 24px;
      margin-bottom: 4px;
      @include ellipsis();
    }
    &__unread {
      background-color: $green;
      color: $white;
      border-radius: 18px;
      min-width: 18px;
      padding: 0 6px;
      height: 18px;
      &--muted {
        background-color: $black50;
      }
    }
  }

  &-user {
    flex: 1 1 auto;
    background: $white;
    height: 100vh;
    @supports (-webkit-touch-callout: none) {
      height: -webkit-fill-available;
    }
    &--empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px;
      flex: 1;

      .text-center{
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
      }

      .img {
        width: 100%;
        background: url('#{$imagesUrl}/emptyChats.svg') top center no-repeat;
        background-size: contain;
        flex: 1;
      }
    }

    &--header {
      padding: 8px 4.2%;
      z-index: 150;
      background: $white;
    }

    .messages {
      flex: 1 1 auto;
      overflow-y: auto;
      -webkit-overflow-scrolling:touch;
      min-height: 0;
      height: 100%;
      display: flex;
      flex-direction: column;

      &-group {
        &:first-child {
          margin-top: auto;
        }
      }

      &-timeline {
        font-size: 13px;
        line-height: 16px;
        color: $black50;
        text-align: center;
        margin: 20px;
      }

      &-item {
        margin-bottom: 8px;
        &__body {
          background: $green10;
          border-radius: 6px;
          font-size: 15px;
          line-height: 20px;
          padding: 8px;
          min-height: 36px;
          word-break: break-word;
          white-space: pre-line;

          &.inbox {
            background: $black5;
          }

          &-payment {
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            color: $green;
          }
        }
        &__time {
          font-size: 10px;
          line-height: 16px;
          margin-left: 10px;
          color: $black50
        }
        &__icon {
          margin-left: 6px;
        }
      }

      &-form {
        padding-top: 12px;
        padding-bottom: 12px;
        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        textarea {
          flex: 1;
          border-radius: 50px;
          background: $black5;
          font-size: 15px;
          line-height: 20px;
          padding: 8px 16px;
          border: none;
          outline: none;
          margin: 0 16px;
          height: 36px;
          resize: none;
        }

        &--button {
          width: 24px;
          height: 24px;

          &:first-child {
            border: 2px solid $green;
            border-radius: 12px;

            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }

    &--footer {
      width: 100%;
      max-width: 480px;
      background: $white;
    }
  }
  &-lock {
    margin-top: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  &-expired {
    margin-bottom: 60px;
  }
}

.settings-chats {
  .title {
    @include bold1724();
    margin-bottom: 16px;
  }
  .text {
    @include medium1320();
    color: $black50;
  }
  .subTitle {
    @include semiBold1524();
    text-align: left;
    margin-bottom: 1rem;
  }
}

.voice {
  position: absolute;
  bottom: 0;
  right: 25px;
  display: flex;
  align-items: center;
  min-height: 36px;
  .btn {
    outline: 0 !important;
    box-shadow: none !important;
    width: 24px;
    height: 36px;
    padding: 0;
  }
}

.messages-form--button {
  outline:0!important;
  box-shadow: none !important;
}
.messages-form--button:hover {
  outline:0!important;
  box-shadow: none !important;
}
.messages-form--button:focus {
  outline: none!important;
  box-shadow: none !important;
}
.messages-form--button:active {
  outline: none!important;
  box-shadow: none !important;
}
