@import "../variables";
@import "../mixins";

.file {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  & > div {
    margin-left: 10px;
    flex: 1;
  }
  .filename {
    @include ellipsis(calc(100vw - 70px));
  }
}

.file-line {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid $black10;
  &>div>.deleteicon>input {
    height: auto!important;
  }
  .filename {
    @include ellipsis(null);
  }
  .player-audio-controls {
    .filename {
      @include ellipsis(calc(100vw - 140px));
    }
  }
}

.favoriteIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  @include paddingContent();
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid $black5;
  height: 44px;
  span {
    display: inline-flex;
    @include semiBold1316();
    margin-left: 6px;
    margin-right: 4px;
    width: 50px;
    color: $black50;
  }
  .empty {
    width: 84px;
  }
}

.socialLinks {
  &-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  &-item, &-item:hover {
    display: flex;
    align-items: center;
    text-decoration: none !important;
    color: $black !important;
    .center-block {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .image {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    span {
      @include medium1016();
      margin-top: 12px;
      color: $black50;
    }
  }
  &-item:last-child{
    margin-right: 0;
  }

}

.uploadWelcomeBlock {
  background-color: $green10;
  border-radius: 6px;
  padding: 8px;
  height: 194px;
  .frame {
    height: 100%;
    border: 1px dashed $black10;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      @include semiBold1316();
    }
    .addButton {
      @include semiBold1316();
      color: $green;
      cursor: pointer;
    }
  }
}

.uploadedWelcomeMedia {
  position: relative;
  .welcomeImage {
    width: 100%;
    border-radius: 6px;
  }
  .close {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    text-shadow: none !important;
    opacity: 1 !important;
  }
}