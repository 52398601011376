@import "../variables";
@import "../mixins";

.profile-about {
  &-item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    .label {
      color: $black50;
      margin-right: 20px;
      width: 100px;
    }
    .value {
      flex: 1;
      text-align: right;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 12px;
    }
    .edit {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 12px;
      div:last-child {
        flex: 1
      }
    }
    div {
      @include medium1524();
    }
    span.placeholder {
      color: $black50
    }
    img {
      width: 6px;
      height: 12px;
      cursor: pointer;
    }
    img.link {
      margin-right: 10px;
      width: 16px;
      height: 16px;
    }
  }

  textarea {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
  }
}
