@import "../variables";
@import "../mixins";

.list-influencer {
  flex:1;
  display: flex;
  height: 64px;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  & > img {
    width: 64px;
    height: 64px;
    border-radius: 32px;
  }
  &-content {
    padding-left: 8px;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 64px;
    justify-content: flex-start;
  }
  &-teaser {
    @include medium1318();
    color: $black50;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.tab-content-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include paddingContent();
  .info {
    background: $black5;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    span:first-child {
      @include semiBold1724();
      margin-bottom: 8px;
    }
    span:last-child {
      @include medium1316();
      color: $black50;
    }
  }
}