.list-payment-method {
  flex:1;
  display: flex;
  height: 48px;
  flex-direction: row;
  padding-right: 8px;
  align-items: center;
  & > img {
    width: 24px;
    height: 24px;
  }
  &-content {
    padding-left: 8px;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}