@font-face {
  font-family: 'Manrope';
  src: local('Manrope-Bold'), url('./manrope-bold.woff') format('woff'),
  url('./manrope-bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: local('Manrope-Regular'), url('./manrope-regular.woff') format('woff'),
  url('./manrope-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Manrope';
  src: local('Manrope-Medium'), url('./manrope-medium.woff') format('woff'),
  url('./manrope-medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: local('Manrope-Semibold'), url('./manrope-semibold.woff') format('woff'),
  url('./manrope-semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}